import { Injectable } from '@angular/core';
import { RQHttpService } from '../../../core/services/http.service';
import { HttpOptionsViewModel } from '../../../core/models/http-options.model';
import { environment } from '../../../../environments/environment';
import { Pagination } from '../../models/pagination.model';
import { ScenarioMitreObservationModel } from '../../models/scenario-mitre-observation-model.model';
import { PaginationFilter } from '../../models/pagination-filter.model';
import { ScenarioMitreObservationFilterModel } from '../../models/scenario-mitre-observation-filter-model.model';
import { IActionResult } from '../../models/iaction-result.model';
import { ScenarioMitreObservationUpdateModel } from '../../models/scenario-mitre-observation-update-model.model';
import { ScenarioMitreObservationAssignModel } from '../../models/scenario-mitre-observation-assign-model.model';

@Injectable()
export class ScenarioMitreObservationDataService {
	constructor(private httpService: RQHttpService) {}

	public getByScenarioId(
		scenarioId: string,
		pagination: PaginationFilter,
		filter: ScenarioMitreObservationFilterModel,
		options = new HttpOptionsViewModel()
	): Promise<Pagination<ScenarioMitreObservationModel>> {
		options.params = this.httpService.getHttpParams({
			scenarioId,
			pagination,
			filter
		});

		return this.httpService
			.request<Pagination<ScenarioMitreObservationModel>>(
				'get',
				environment.api + '/api/Scenario/ScenarioMitreObservation/GetByScenarioId',
				options
			)
			.toPromise();
	}

	public update(updateModel: ScenarioMitreObservationUpdateModel, options = new HttpOptionsViewModel()): Promise<IActionResult> {
		options.body = updateModel;

		return this.httpService
			.request<IActionResult>('put', environment.api + '/api/Scenario/ScenarioMitreObservation/Update', options)
			.toPromise();
	}

	public assign(assignModel: ScenarioMitreObservationAssignModel, options = new HttpOptionsViewModel()): Promise<void> {
		options.body = assignModel;

		return this.httpService.request<void>('post', environment.api + '/api/Scenario/ScenarioMitreObservation/Assign', options).toPromise();
	}

	public revertDefaults(scenarioId: string, options = new HttpOptionsViewModel()): Promise<IActionResult> {
		options.params = this.httpService.getHttpParams({
			scenarioId
		});

		return this.httpService
			.request<IActionResult>('put', environment.api + '/api/Scenario/ScenarioMitreObservation/RevertDefaults', options)
			.toPromise();
	}
}
